import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("名称"),
    dataIndex: "material_name",
  },
  {
    title: T("编号"),
    dataIndex: "material_number",
  },
  {
    title: T("条码"),
    dataIndex: "material_barcode",
  },
  {
    title: T("规格"),
    dataIndex: "material_spec",
  },
  {
    title: T("库存数量"),
    dataIndex: "quantity",
  },
  {
    title: T("可用数量"),
    dataIndex: "available_quantity",
  },
  {
    title: T("单位"),
    dataIndex: "material_unit",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];
